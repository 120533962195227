import React, { useRef } from 'react';
import image from '../../assets/images/SIlja-Start-Uebermich.jpg';
import useIntersection from '../../hooks/intersection';
import './Silja2.scss';

const Silja2 = () => {
  const box = useRef();
  const isIntersecting = useIntersection(box);

  return (
    <div
      ref={box}
      className={'Silja2' + (isIntersecting ? ' is-intersecting' : '')}
    >
      <div
        className="Silja2__image"
        itemScope
        itemType="https://schema.org/ImageObject"
      >
        <img src={image} alt="Silja Tyllilä" itemProp="thumbnailUrl" />
      </div>
      <svg viewBox="0 0 100 100" className="Silja2__shape Silja2__shape--1">
        <circle cx="50" cy="50" r="50" />
      </svg>
      <div className={'Silja2__shape Silja2__shape--2'}>
        <svg viewBox="0 0 100 100" className="">
          <rect x="0" y="0" width="100" height="100" rx="25" />
        </svg>
      </div>
    </div>
  );
};

export default Silja2;
