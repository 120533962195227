import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useRef, useState } from 'react';
import useIntersection from '../../hooks/intersection';
import Button from '../Button/Button';
import Header from '../Header/Header';
import './Stage.scss';

const Stage = ({ image }) => {
  const box = useRef();
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const isIntersecting = useIntersection(box, { threshold: 0.5 });

  return (
    <>
      <Header variant={'home'} />
      <div
        ref={box}
        className={
          'Stage' + (isIntersecting && isImgLoaded ? ' is-intersecting' : '')
        }
      >
        <div className="Stage__inner">
          <div className="Stage__image">
            <GatsbyImage
              image={getImage(image)}
              alt="Silja Tyllilä"
              className={'Stage__image-wrapper'}
              onLoad={() => setIsImgLoaded(true)}
            />
            <svg viewBox="0 0 20 20" className={'Stage__circle'}>
              <circle className={'circle circle--1'} r="7" cx="10" cy="10" />
              <circle className={'circle circle--2'} r="7" cx="10" cy="10" />
              <circle className={'circle circle--3'} r="6.5" cx="10" cy="10" />
            </svg>
          </div>
          <hgroup className="Stage__headings">
            <h1 className="Stage__title">
              <span>Coaching.</span> <span>Beratung.</span>{' '}
              <span>Teamentwicklung.</span>
            </h1>
            <div className="Stage__subtitle-wrapper">
              <h2 className="Stage__subtitle">Unterstützung für</h2>
              <div className="Stage__buttons">
                <Button
                  linkTo={'/angebot-unternehmen/'}
                  styles={['l', 'turquoise']}
                >
                  Unternehmen
                </Button>
                <Button linkTo={'/angebot-sport/'} styles={['l', 'green']}>
                  Sportler
                </Button>
                <Button linkTo={'/angebot-privat/'} styles={['l', 'yellow']}>
                  Privatpersonen
                </Button>
              </div>
            </div>
          </hgroup>
        </div>
      </div>
    </>
  );
};
export default Stage;
