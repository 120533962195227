import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import Claim from '../components/Claim/Claim';
import Section from '../components/Section/Section';
import Silja2 from '../components/Silja2/Silja2';
import Stage3 from '../components/Stage3/Stage';
import Button from '../components/Button/Button';
import { initML } from '../staticFunctions/mailerlite-init';
// import Stage from '../components/Stage/Stage';

const getMyImage = (src, alt) =>
  typeof src === 'string' ? (
    <img alt={alt} src={src} />
  ) : (
    <GatsbyImage alt={alt} image={getImage(src)} />
  );

const getAngebotColor = (type) =>
  type === 'privat'
    ? 'yellow'
    : type === 'unternehmen'
      ? 'turquoise-light'
      : type === 'sport'
        ? 'green'
        : 'white';

const Home = ({
  stageImage,
  claim,
  aktuelles,
  about,
  angebote,
  podcast,
  newsletter,
}) => {
  useEffect(() => {
    initML();
  }, []);

  const openMLPopup = () =>
    window.ml('accounts', '3504722', 't2k0x4q3r7', 'load')(
      'webforms',
      '5655384',
      'm2r4j9',
      'show',
    );

  return (
    <>
      <Stage3 image={stageImage} />
      {claim && !claim.hide && <Claim content={claim.text} />}
      {aktuelles && !aktuelles.hide && (
        <Section
          type="turquoise"
          align="right"
          image={getMyImage(aktuelles.image, aktuelles.title)}
        >
          <h2 className="Section__title">{aktuelles.title}</h2>
          <p>{aktuelles.text}</p>
          {aktuelles.buttonLink && (
            <p>
              <Button linkTo={aktuelles.buttonLink} styles={['blue', 'right']}>
                {aktuelles.buttonLabel || 'Mehr erfahren'}
              </Button>
            </p>
          )}
        </Section>
      )}
      {about && !about.hide && (
        <Section type="white" align="left" image={<Silja2 />}>
          <h2 className="Section__title">{about?.title}</h2>
          <p>{about?.text}</p>
          <p>
            <Button linkTo={'/uebermich'} styles={['turquoise', 'right']}>
              {about?.buttonLabel || 'Mehr über mich'}
            </Button>
          </p>
        </Section>
      )}

      {angebote.map(
        ({ type, title, text, buttonLink, buttonLabel, image, hide }, index) =>
          !hide && (
            <Section
              type={getAngebotColor(type)}
              align={!(index % 2) ? 'right' : 'left'}
              image={getMyImage(image, title)}
              key={title}
            >
              <h2 className="Section__title">{title}</h2>
              <p>{text}</p>
              <p>
                <Button
                  linkTo={buttonLink}
                  styles={[getAngebotColor(type), 'right']}
                >
                  {buttonLabel || 'Mehr…'}
                </Button>
              </p>
            </Section>
          ),
      )}

      {newsletter && !newsletter.hide && (
        <Section
          align="right"
          type={'turquoise-light'}
          graphics
          // image={
          //   <InlineSVG
          //     src={graphics}
          //     width={''}
          //     style={{ fill: '#a0e1e6', transform: 'rotate(-10deg)' }}
          //   />
          // }
        >
          <h2 className="Section__title">{newsletter.title}</h2>
          <p>{newsletter.text}</p>
          <p>
            <Button styles={['turquoise', 'right']} clickHandler={openMLPopup}>
              {newsletter.buttonLabel}
            </Button>
          </p>
        </Section>
      )}

      {podcast && !podcast.hide && (
        <Section
          align="left"
          image={
            getMyImage(podcast.image, podcast.title)
            // <Link to={'/podcast'}>
            //   <SiljaPodcast />
            // </Link>
          }
        >
          <h2 className="Section__title">{podcast?.title}</h2>
          <p>{podcast?.text}</p>
          <p>
            <Button linkTo={'/podcast'} styles={['yellow', 'right']}>
              {podcast?.buttonLabel}
            </Button>
          </p>
        </Section>
      )}
    </>
  );
};

export default Home;
