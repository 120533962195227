export const initML = () => {
  const fName = 'ml';
  window['MailerLiteObject'] = fName;
  function f() {
    const config = { a: arguments, q: [] };
    const r = this.push(config);
    return 'number' != typeof r ? r : f.bind(config.q);
  }
  f.q = f.q || [];
  window[fName] = window[fName] || f.bind(f.q);
  window[fName].q = window[fName].q || f.q;
  const url = 'https://static.mailerlite.com/js/universal.js';
  const el = document.createElement('script');
  const _ = document.getElementsByTagName('script')[0];
  el.async = true;
  el.src = url + '?v' + ~~(new Date().getTime() / 1000000);
  _.parentNode.insertBefore(el, _);
};
