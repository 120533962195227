import React from 'react';
import Section from '../Section/Section';
import './Claim.scss';

const Claim = ({ content }) => (
  <Section>
    <div className="Claim">{content}</div>
  </Section>
);

export default Claim;
