import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Home from '../templates/Home';

const HomePage = ({ data, location }) => {
  const {
    seoTitle,
    description,
    title,
    stageImage,
    claim,
    about,
    aktuelles,
    angebote,
    podcast,
    newsletter,
  } = data?.markdownRemark.frontmatter;

  return (
    <Layout location={location}>
      <Seo
        title={seoTitle || title}
        description={description}
        location={location}
      />
      <Home
        stageImage={stageImage}
        claim={claim}
        aktuelles={aktuelles}
        about={about}
        angebote={angebote}
        podcast={podcast}
        newsletter={newsletter}
      />
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      id
      frontmatter {
        seoTitle
        title
        description
        stageImage {
          childImageSharp {
            gatsbyImageData(width: 800, height: 800, placeholder: BLURRED)
          }
        }
        claim {
          text
          hide
        }
        aktuelles {
          title
          text
          buttonLabel
          buttonLink
          hide
          image {
            childImageSharp {
              gatsbyImageData(width: 400, height: 400, placeholder: BLURRED)
            }
          }
        }
        about {
          title
          text
          hide
        }
        angebote {
          type
          title
          text
          buttonLabel
          buttonLink
          hide
          image {
            childImageSharp {
              gatsbyImageData(width: 400, height: 400, placeholder: BLURRED)
            }
          }
        }
        podcast {
          title
          text
          buttonLabel
          hide
          image {
            childImageSharp {
              gatsbyImageData(width: 400, height: 400, placeholder: BLURRED)
            }
          }
        }
        newsletter {
          title
          text
          buttonLabel
          hide
        }
      }
    }
    #    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
    #      frontmatter {
    #        title
    #      }
    #    }
    # allMarkdownRemark {
    #   nodes {
    #     html
    #     frontmatter {
    #       title
    #     }
    #   }
    # }
  }
`;
